PESAPP.exhibitor = (function(m, $) {

  m.options = {
    template: "base-exhibitor-list",
    sorts: "ExhibitingAs",
    controllers: {
      keyword: {
        filters: "ExhibitingAs,BoothNumber"
      }
    },
    combineDupes: true,
    linkBase: false,
    noResults: "<strong>Sorry</strong> Nothing to see here",
    ajax: {
      dataType: "jsonp"
    }
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.controller, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function(id) {
      var self = PESAPP.extend(parent, {view: $(this)});
      var options = self.options;
      var promise = self.getData();
      self.noResults = options.noResults;
      self.id = id;
      self.getTemplate();
      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {"items": response});
        var filtered = _filter(holder.items, self.options);
        self.data.items = filtered;
        self.data.model = filtered;
        self.updateTemplate();
        self.controllerInit();
      });
    });
  };

  // 4. Private methods, properties

  function _filter(exhibitors, options) {
    var sorts = options.sorts;
    var combineDupes = options.combineDupes;
    var filtered = [];
    var dupes = [];
    var index;
    var exhibitingAs;
    var boothNumber;

    if (combineDupes) {
      // Combine dupes
      $.each(exhibitors, function(ii, exhibitor) {
        exhibitingAs = exhibitor.Details.ExhibitingAs;
        if (dupes.indexOf(exhibitingAs) > -1) {
          $.each(filtered, function(iii, item) {
            if (item.ExhibitingAs === exhibitingAs) {
              index = iii;
              return false;
            }
          });
          filtered[index].BoothNumber += ',' + exhibitor.BoothNumber;
        }
        else {
          exhibitor.ExhibitingAs = exhibitingAs;
          dupes.push(exhibitingAs);
          filtered.push(exhibitor);
        }
      });
      $.each(filtered, function(ii, exhibitor) {
        boothNumber = exhibitor.BoothNumber;
        if (boothNumber.indexOf(',') > -1) {
          boothNumber = boothNumber.split(',');
          boothNumber = boothNumber.sort(function(a, b) {
            return a - b;
          });
          exhibitor.BoothNumber = boothNumber.join(', ');
        }
      });
    }
    else {
      // Don't combine dupes
      $.each(exhibitors, function(ii, exhibitor) {
        exhibitingAs = exhibitor.Details.ExhibitingAs;
        exhibitor.ExhibitingAs = exhibitingAs;
        filtered.push(exhibitor);
      });
    }

    filtered = PESAPP.sort(filtered, sorts);

    return filtered;
  }

  return m;

})(PESAPP.exhibitor || {}, jQuery);

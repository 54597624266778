(function ($) {
  'use strict';

  $.fn.navbarSticky = function (settings) {
    return this.each(function () {
      var $navbar = $(this);
      var s = $.extend({
          paddingEl: null,
          paddingBottom: true,
          stickyClass: 'navbar-fixed-top',
          upscroll: false,
          upscrollClass: 'upscroll',
          scrolldirClass: 'scrolldir'
        }, settings);
      
      if ($navbar.hasClass(s.stickyClass)) {
        toggleSticky();
        $(s.paddingEl).css('padding-bottom: 0px; padding-top: 0px;');
      }
      
      var $padding = s.paddingEl ? $(s.paddingEl) : $navbar.prev();
      var padHeight = $navbar.outerHeight(true);
      var navbarOffset = $navbar.offset().top;
      var offsetY = s.upscroll ? navbarOffset + padHeight : navbarOffset;
      var padLoc = s.paddingBottom ? 'bottom' : 'top';
      var scrollY;
      var lastScroll = 0;

      if (s.upscroll) $navbar.addClass(s.scrolldirClass);

      function toggleSticky() {

        if (!$navbar.hasClass(s.stickyClass)) $padding.css('padding-' + padLoc, padHeight);

        else $padding.css('padding-' + padLoc, 0);

        $navbar.toggleClass(s.stickyClass);

      }

      $(function () {

        $(window).scroll(function () {
          scrollY = $(window).scrollTop();

          if (scrollY >= offsetY && !$navbar.hasClass(s.stickyClass)) toggleSticky();

          if (scrollY < navbarOffset && $navbar.hasClass(s.stickyClass)) toggleSticky();

          if (s.upscroll) {

            if (scrollY < lastScroll) $navbar.addClass(s.upscrollClass);

            else if ($navbar.hasClass(s.upscrollClass)) $navbar.removeClass(s.upscrollClass);

            lastScroll = scrollY;

          }

        });

      });

    });

  };

}(jQuery));
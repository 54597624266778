PESAPP.sponsorOpps = (function(m, $) {

  m.options = {
    soldBottom: true,
    featuredTop: true,
    sorts: 'title,price',
    template: 'base-sponsor-opps',
    controllers: {
      keyword: {
        filters: 'title,content,excerpt,level'
      }
    },
    contact: {
      link: '',
      text: 'Contact Sales'
    },
    inclusion: {
      link: '',
      text: 'Inclusion'
    },
    
    // typeConfig: {
    //   propertyName: '',
    //   types:{}
    // },
	  // formatConfig: {
    //   propertyName: ''
    // },
    // levelConfig: {
    //   propertyName: '',
    //   levels: {}
    // }
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);
    parent = PESAPP.extend(PESAPP.controller, parent);
    parent = PESAPP.extend(PESAPP.getData, parent);
    parent = PESAPP.extend(PESAPP.template, parent);

    views.each(function() {
      var view = $(this);
      var self = PESAPP.extend(parent, { view: view });
      var promise = self.getData();

      // sort stuff
      var data = self.data;
      var options = self.options;

      self.getTemplate();
      promise.on('dataReady', function(event, response) {
        // Make a new item for the response Issue #43
        var holder = PESAPP.extend({}, {'items': response});
        self.data.items = _mapItems(holder.items.posts, options);
        self.data.model = self.data.items;
        self.data.items = PESAPP.sort(self.data.items, options.sorts);
        self.data.items = _temp(self.data.items, options);
        self.data.items = PESAPP.hash.check('slug', self.data.items, self.data.model);
        self.updateTemplate();
        self.controllerInit();
      });
    });
  };

  function _temp(items, options) {
    var featured = [];
    var sold = [];
    var basic = [];

    $.each(items, function(i, item) {
      if (options.soldBottom && typeof item.custom_fields.opp_sold !== 'undefined') {
        sold.push(item);
      }
      else if (options.featuredTop && typeof item.custom_fields.opp_featured !== 'undefined') {
        featured.push(item);
      }
      else {
        basic.push(item);
      }
    });

    return featured.concat(basic, sold);
  }



  function _mapItems(items, options) {
        
    var lConfig = options.levelConfig;
    var lLevels = lConfig.levels;
    var lProp = lConfig.propertyName;

    var tConfig = options.typeConfig;
	  var tTypes = tConfig.types;
    var tProp = tConfig.propertyName;

	  var fConfig = options.formatConfig;
	  var fFormats = fConfig.formats;
    var fProp = fConfig.propertyName;
    



    $.each(items, function(i, item) {

      item.level = '';
      item.type1 = '';
      item.price = '';
      item.levelSort = 'zzz';
	    item.count = 0;
      item.sold = false;
      item.featured = false;
      item.available = '';
      item.pastDeadline = false;
      item.format = '';
      


      if (typeof item.custom_fields[lProp] !== 'undefined') {
        // Take the first item from the array, only 1 item is allowed
        item.level = item.custom_fields[lProp][0];
        item.levelSlug = PESAPP.slugify(item.level);
        item.levelConfig = lLevels[item.level] || {};
        item.levelSort = item.levelConfig.sort || item.custom_fields[lProp][0];

        // if (typeof item.levelConfig.label !== 'undefined') {
        //   item.level = item.levelConfig.label;
        // }
        // if (typeof item.levelConfig.class !== 'undefined') {
        //   item.levelClass = item.levelConfig.class;
        // }
        if (typeof item.levelConfig.label !== 'undefined') {
          if(item.levelConfig.label == 'Platinum'){
             item.level = 'Platinum'
             item.levelClass = 'opp-level--1';
           }
           else{
             item.level = item.levelConfig.label
           }
        }
        if (typeof item.levelConfig.label !== 'undefined') {
          if(item.levelConfig.label == 'Gold'){
             item.level = 'Gold'
             item.levelClass = 'opp-level--2';
           }
           else{
             item.level = item.levelConfig.label
           }
        }
        if (typeof item.levelConfig.label !== 'undefined') {
          if(item.levelConfig.label == 'Silver'){
             item.level = 'Silver'
             item.levelClass = 'opp-level--3';
           }
           else{
             item.level = item.levelConfig.label
           }
        }
        if (typeof item.levelConfig.label !== 'undefined') {
          if(item.levelConfig.label == 'Title Sponsor'){
             item.level = 'Title Sponsor'
             item.levelClass = 'opp-level--4';
           }
           else{
             item.level = item.levelConfig.label
           }
        }
        if (typeof item.levelConfig.label !== 'undefined') {
          if(item.levelConfig.label == 'Major Sponsor'){
             item.level = 'Major Sponsor'
             item.levelClass = 'opp-level--5';
           }
           else{
             item.level = item.levelConfig.label
           }
        }
        if (typeof item.levelConfig.label !== 'undefined') {
          if(item.levelConfig.label == 'Sponsor'){
             item.level = 'Sponsor'
             item.levelClass = 'opp-level--6';
           }
           else{
             item.level = item.levelConfig.label
           }
        }
        if (typeof item.levelConfig.label !== 'undefined') {
          if(item.levelConfig.label == 'Premier Title'){
             item.level = 'Premier Title'
             item.levelClass = 'opp-level--7';
           }
           else{
             item.level = item.levelConfig.label
           }
        }
        if(item.level == '8'){
          item.level = 'Marketing Opportunity'
          item.levelClass = 'opp-format opp-level--8';
        }
        // if (typeof item.levelConfig.label !== 'undefined') {
        //   if(item.levelConfig.label == 'Marketing Opportunity'){
        //      item.level = 'Marketing Opportunity'
        //      item.levelClass = 'opp-level--8';
        //    }
        //    else{
        //      item.level = item.levelConfig.label
        //    }
        // }
      }

      // if (typeof item.custom_fields[tProp] !== 'undefined') {
        
      //   item.type1 = item.custom_fields[tProp][0]
      //   item.typeSlug = PESAPP.slugify(item.type1);
		  //   item.typeConfig = tTypes[item.type1] || {};
      //   item.typeSort = item.typeConfig.sort || item.custom_fields[tProp][0]
      //   var res = $.map(item.type1,function (value){
      //     return tTypes[value]
      //   })

      //   item.typeConfig.arrayProperty = res;

      //   console.log(item.type1,'item.type1')
      //   console.log(res,'res')
      //   console.log(item.typeSlug,'item.typeSlug')
      //   console.log(item.typeConfig,'item.typeConfig')
      //   console.log(item.typeSort,'item.typeSort')

      //   if (item.typeConfig.arrayProperty !== 'undefined' || item.typeConfig.arrayProperty !== '') {
      //       $.each(item.typeConfig.arrayProperty,function (index, item){
      //         if (typeof item.label !== 'undefined') {
      //           item.type1 = item.label;
      //           console.log(item,'res item');
      //           console.log(item.type1,'res item.type1');
      //         }
      //         if (typeof item.class !== 'undefined') {
      //           item.typeClass = item.class;
      //           console.log(item.typeClass,'res item.typeClass');
      //         }
      //       })
      //   }
		    
      // }
	  
	   if (typeof item.custom_fields[fProp] !== 'undefined') {
        // Take the first item from the array, only 1 item is allowed

        item.format = item.custom_fields[fProp][0];
        item.formatSlug = PESAPP.slugify(item.format);
		    item.formatConfig = fFormats[item.format] || {};
        item.formatSort = item.formatConfig.sort || item.custom_fields[fProp][0];
		    
        if (typeof item.formatConfig.label !== 'undefined') {
          if(item.formatConfig.label == 'In-Person'){
             item.format = 'In-Person'
             item.formatClass = 'opp-format opp-format--2';
           }
           else{
             item.format = item.formatConfig.label
           }
        }
        if (typeof item.formatConfig.label !== 'undefined') {
          if(item.formatConfig.label == 'Virtual'){
             item.format = 'Virtual'
             item.formatClass = 'opp-format opp-format--1';
           }
           else{
             item.format = item.formatConfig.label
           }
        }
        
        if(item.format == '4'){
            item.format = 'Digital'
            item.formatClass = 'opp-format opp-format--4';
        }
        if(item.format == 'both'){
          item.format = 'In-Person & Virtual'
          item.formatClass = 'opp-format opp-format--3';
        }
        // if (typeof item.formatConfig.class !== 'undefined') {
        //   item.formatClass = item.formatConfig.class;
        // }

      }


        
      if((typeof item.custom_fields.opp_sold !== "undefined") && (item.custom_fields.opp_sold.toString() == "on")){
        $.each(item.custom_fields.opp_sold, function(ii, sold) {
            item.sold = true
        });
      }

      if((typeof item.custom_fields.opp_sold !== "undefined") && new Date() > new Date(item.custom_fields.opp_deadline)){
       
        item.pastDeadline = true
      }
     
      if(item.pastDeadline == "true"){
        if (typeof(item.custom_fields.opp_deadline) !== "undefined") {
           item.available = new Date() > new Date(item.custom_fields.opp_deadline);
        }
      }
      
      if(typeof item.custom_fields.opp_sold !== "undefined"){
        if(item.custom_fields.opp_sold.toString() == "on"){
          item.available = "Sold"
        }
        else if(item.custom_fields.opp_sold.toString() == "undefined"){
        item.available = "Available"
        }
        else{
        item.available = item.custom_fields.opp_sold.toString();
        }
      }

      console.log(item.custom_fields.opp_inclusions_label,'inclusion')

      if (item.custom_fields.opp_featured) {
        $.each(item.custom_fields.opp_featured, function(ii, featured) {
          item.featured = true;
        });
      }

      if (item.custom_fields.opp_numeric_cost) {
        $.each(item.custom_fields.opp_numeric_cost, function(ii, opp_numeric_cost) {
          item.price = item.custom_fields.opp_numeric_cost.toString();
          item.price = parseInt(item.price);
        });
      }
    });
    
    console.log(items,'items')
    
    return items;

    
  }
  
  return m;

}(PESAPP.sponsorOpps || {}, jQuery));

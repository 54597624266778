PESAPP.dateFormatter = (function(m, $) {

  m.options = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    hours: ["12", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
    suffix: ["am", "pm"]
  };

  m.format = function(dateString) {
    var options = this.options;
    var d = new Date(dateString);
    var day = options.days[d.getDay()];
    var hour = options.hours[d.getHours()];
    var minute = d.getMinutes() < 10 ? "0" +  d.getMinutes() : d.getMinutes();
    var suffix = d.getHours() < 11 ? options.suffix[0] : options.suffix[1];

    return {
      day: day,
      date: options.months[d.getMonth()] + " " + d.getDate(),
      time: hour + ":" + minute + suffix
    };
  };

  return m;

})(PESAPP.dateFormatter || {}, jQuery);

// Wrapper for working with Handlebars templates
// Example usage:
// var template = PESAPP.extend(PESAPP.template);
// Most components provide examples of extending (not controller.js)

PESAPP.template = (function(m, $) {

  m.options  = {};

  m.getTemplate = function() {
    var self = this;
    var view = $(self.view);
    var template = view.data('template') || self.options.template;
		var source;

    if (PESAPP.templateCache[template]) {
		  self.template = PESAPP.templateCache[template];
		}
		else {
		  source = $(template).html();
			self.template = Handlebars.compile(source);
		}

		return self;
  };

  m.updateTemplate = function() {
    var self = this;
    var view = $(self.view);
    var html = self.template(self);

    view.html(html).removeClass('loading');
    view.trigger('templateReady');

    return self;
	};

  return m;

}(PESAPP.template || {}, jQuery));

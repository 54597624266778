// Wrapper for generating flexible JavaScript models based on HTML
// Example usage:
// var htmlModel = PESAPP.extend(PESAPP.htmlModel);
// See sponsor-opps.js for extending

PESAPP.htmlModel = (function(m, $) {

  m.data = {
    ready: false
  };
  m.options = {};

	m.compileModel = function() {
    var self = this;
    var src = self.src || $(self.view).data('model');
    var html = $(src).html();

    $(src).after('<div data-x-html-model="' + src + '" style="display: none !important;">' + html + '</div>');

		var htmlTemp = $('[data-x-html-model="' + src + '"]');
		var children = htmlTemp.children();
    var grandChildren;
    var items = [];

		$.each(children, function(c, child) {
      grandChildren = $(child).children();
		  items.push(_returnData(child));

			if (grandChildren.length > 0) {
			  items[c].subItems = [];

				$.each(grandChildren, function(g, grandChild) {
					items[c].subItems.push(_returnData(grandChild));
				});
			}
		});

		htmlTemp.remove();
    self.src = self;
    self.data.items = items;
    self.data.ready = true;

    return self;
	};

  function _returnData(element) {

    element = $(element);
	  var item = {};
		var elementData = element.data();
		var elementHtml = element.children('[data-html]');
		var property;

		elementHtml.each(function(htmlI, htmlEl) {
			htmlEl = $(htmlEl);
			item['html-' + htmlEl.data('html')] = htmlEl.html();
		});

		for (property in elementData) {
			if (elementData.hasOwnProperty(property)) {
				item[property] = elementData[property];
			}
		}

		return item;
	}

	return m;

}(PESAPP.htmlModel || {}, jQuery));

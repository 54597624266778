(function ($) {
  'use strict';

  $.fn.sessionsHeadings = function () {
    return this.each(function () {
      var view = $(this);
      var datetime = [];
      var dtLen;
      var i;
      var dtCurrEl;
      var dtPrevEl;

      var datetimeClean = function dtClean(datetime) {
        return datetime && datetime.toLowerCase().split(' ', 3) || false;
      };

      datetime = view.find('*[datetime]');
      dtLen = datetime.length;

      for (i = 0; i < dtLen; i += 1) {
        dtCurrEl = $(datetime[i]);
        dtPrevEl = $(datetime[(i - 1)]);

        if (
          datetimeClean(dtCurrEl.attr('datetime')).toString()
          !== datetimeClean(dtPrevEl.attr('datetime')).toString()
        ) {
          dtCurrEl.find('.sessions-heading').first().removeClass('hidden');
        }
      }
    });
  };
}(jQuery));

// Wrapper for making AJAX calls
// Example usage:
// var getData = PESAPP.extend(PESAPP.getData);

PESAPP.getData = (function(m, $) {

  m.cache = {};
  m.data = {};
  m.options = {
    ajax: {},
    yql: {
		  mode: false,
			src: 'json',
      format: 'json'
		}
	};

  m.getData = function() {
	  var self = this;
    var url = self.url;
    var options = self.options;
    var yql = options.yql;
    var ajax = options.ajax;
    var promise;

    if (!(url in m.cache)) {
      promise = m.cache[url] = $({});

      if (yql.mode) {
        url = 'https://query.yahooapis.com/v1/public/yql?q=' +
          encodeURIComponent(
            'select * from ' + yql.src + ' where url="' + url + '"'
          ) + '&format=' + yql.format;
      }
      ajax.url = url;

      $.ajax(ajax)
        .done(function(response) {
          response = options.alias ? _aliasResponse(response, options.alias) : response;
          response = _limitResponse(response, options.limitTo, options.displayAt);
  				promise.trigger('dataReady', [response]);
          promise.response = response;
        })
        .fail(function(error) {
          promise.trigger('dataReady', [error]);
        });

      return promise;
    }
    else {

      return m.cache[url];
    }
	};

  function _aliasResponse(response, alias) {
	  alias = alias.split('.');

		$.each(alias, function(p, property) {
		  response = response[property];
		});

		return response;
	}

  function _limitResponse(items, limitTo, displayAt) {
    var itemsLen = items.length;
    var limited = [];

    if (limitTo && itemsLen > limitTo) {
      limited = items.splice(0, limitTo);
    }
    else if (displayAt && itemsLen >= displayAt) {
      limited = items;
    }
    else if (!displayAt && !limitTo) {
      limited = items;
    }

    return limited;
  }

  return m;

}(PESAPP.getData || {}, jQuery));

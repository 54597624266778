(function (Handlebars) {

  Handlebars.registerHelper('groupEach', function(every, context, options) {
    var out = '';
    var subcontext = [];
    var i;
    var first = false;

    if (context && context.length > 0) {

      for (i = 0; i < context.length; i += 1) {

        context[i].contextIndex = i;

        if (i === 0) { first = true; }
        if (i > 0 && i % every === 0) {

          out += options.fn(subcontext);
          subcontext = [];
          first = false;
        }

        subcontext.push(context[i]);
      }

      out += options.fn(subcontext);
    }

    return out;
  });

  Handlebars.registerHelper('groupIndex', function(every, contextIndex, options) {

    return contextIndex / every;
  });

  Handlebars.registerHelper('pastDeadline', function(opp_date, options) {
    var today = new Date();
    opp_date = new Date(opp_date);
    if (today > opp_date) {
      return options.fn(this);
    }
  });

  Handlebars.registerHelper('quantityHider', function(qty){
    if (qty === '0'){
      return 'Sold Out!';
    }
    else if (qty) {
      'Quantity: ' + qty;
    }
    else {
      return '';
    }
  });

  Handlebars.registerHelper('eq', function( a, b ){
    var next =  arguments[arguments.length-1];
    return (a === b) ? next.fn(this) : next.inverse(this);
  });

  Handlebars.registerHelper('neq', function( a, b ){
    var next =  arguments[arguments.length-1];
    return (a != b) ? next.fn(this) : next.inverse(this);
  });

  Handlebars.registerPartial('sponsor-logo',
    '<div class="base-sponsor-logo sponsor-wrapper sponsor-{{sizeClass}} {{wrapperClass}}">' +
      '{{#if field_sponsor_website}}<a href="{{field_sponsor_website}}" target="_blank">{{/if}}' +
        '<img class="sponsor-img {{field_logo_class}}" src="{{field_logo_image}}" alt="{{title}}">' +
      '{{#if field_sponsor_website}}</a>{{/if}}' +
    '</div>'
  );

  Handlebars.registerPartial('sponsor-logo-no-link',
    '<div class="base-sponsor-logo sponsor-wrapper sponsor-{{sizeClass}} {{wrapperClass}}">' +
      '<img class="sponsor-img {{field_logo_class}}" src="{{field_logo_image}}" alt="{{title}}">' +
    '</div>'
  );

}(Handlebars));

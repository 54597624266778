(function ($) {
  'use strict';

  $.fn.extModal = function (settings) {
    return this.each(function () {

      if (!$.fn.modal) throw new Error('sponsor requires modal.js');

      var $parent = $(this),

        s = $.extend({
          url: $parent.data('url') ? $parent.data('url') : null,
          iframe: $parent.data('iframe') ? $parent.data('iframe') : null
        }, settings);

      function checkurl() {
        var url = window.location.href.toLowerCase(),
          urlarray = s.url.toString().toLowerCase().split(',');

        for (var i = 0; i < urlarray.length; i += 1) {

          if (url.indexOf(urlarray[i]) > -1) {

            $parent.modal();
            break;

          }

        }

      }

      function addiframe() {

        $parent.on('shown.bs.modal', function () {
          var $iframe = $parent.find('iframe').first(),
            $status = $iframe.closest('.load-status');

          $iframe.attr('src', s.iframe);

          if ($status.hasClass('loading')) $status.removeClass('loading');

        });

      }

      if (s.url !== null) checkurl();

      if (s.iframe !== null) addiframe();

    });

  };

}(jQuery));

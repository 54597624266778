PESAPP.carousel = (function(m, $) {
  m.options = {
    controls: {
      show: true,
      template: 'base-carousel-controls',
    },
    indicators: {
      show: true,
      template: 'base-carousel-indicators',
    },
  };

  m.init = function() {
    var parent = this;
    var views = $(parent.view);

    views.each(function(i) {
      var self = PESAPP.extend(parent, {view: $(this)});
      var view = self.view;
      var options = self.options;
      var items = _getItems(view, '.item');
      var indicators = PESAPP.extend(PESAPP.template, {options: options.indicators}).getTemplate();
      var controls = PESAPP.extend(PESAPP.template, {options: options.controls}).getTemplate();
      indicators.id = i;
      indicators.items = items;
      controls.id = i;
      controls.items = items;

      if (items.length > 1) {
        view.attr('id', 'pes-carousel-' + i);
        options.indicators.show && view.prepend(indicators.template(indicators));
        options.controls.show && view.append(controls.template(controls));
        view.carousel(options);
        view.on('slid.bs.carousel', function() {
          _updateIndicators(view);
        });
      }
    });
  };

  function _getItems(parent, item) {
		var items = [];
		parent.find(item).each(function() {
			items.push($(this));
		});
		return items;
	}

  function _updateIndicators(view) {
    var active = view.find('.item.active').index();
    var indicators = view.find('.carousel-indicators li');
    indicators.filter('.active').removeClass('active');
    $(indicators[active]).addClass('active');
  }

  return m;

}(PESAPP.carousel || {}, jQuery));

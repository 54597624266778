// Wrapper for limited hash linking
// Example usage:
// See PESAPP.extend(PESAPP.hash);

PESAPP.hash = (function(m, $) {

  m.options = {
    hashClear: '#/'
  };

  m.check = function(key, items) {
    var hash = location.hash;

    if (hash !== '' && hash !== m.options.hashClear) {
      items = PESAPP.filter(items, [
        { key: key, value: PESAPP.hash.get() }
      ]);
    }

    return items;
  };

  m.clear = function(element) {
    location.hash = m.options.hashClear;
  };

  m.get = function(element) {
    element = element ? $(element) : false;
    var hash;

    if (element) {
      hash = element.data('hash') || element.attr('href');
    }
    else {
      hash = location.hash;
      hash = hash.substr(1, hash.length);
    }

    if ('#' + hash === m.options.hashClear) { return false; }
    else { return hash; }
  };

  return m;

}(PESAPP.hash || {}, jQuery));
